import React, { useEffect, useState } from 'react';
import { RootState } from 'core/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonText,
  IonToolbar
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { logout } from 'features/shared/login/LoginSlice';
import BackButtonHeader from 'core/layout/BackButtonHeader';
import EditAccount from './EditAccount';
import EditIcon from 'assets/icons/edit.svg';
import TrashIcon from 'assets/icons/trash.svg';
import './Account.scss';
import { useHistory } from 'react-router-dom';
import {
  DATE_FORMAT_MONTH_DATE,
  DATE_FORMAT_MONTH_DAY_YEAR,
  firebaseConfig,
  HKPlatform
} from 'core/constants';
import { resetAppToInitialState } from 'features/shared/login/LoginActions';
import DeleteAccount from './DeleteAccount';
import {
  daysUntilRenewal,
  formatPhoneNumber,
  formatSimpleDate,
  gaBtnClickEvent,
  gaToggle
} from 'core/util';
import {
  getAuth,
  fetchSignInMethodsForEmail,
  OAuthProvider,
  GoogleAuthProvider,
  signInWithCredential,
  indexedDBLocalPersistence,
  initializeAuth
} from 'firebase/auth';
import { getApp } from 'firebase/app';
import { Capacitor } from '@capacitor/core';
import { addOutline } from 'ionicons/icons';
import CompletedCheckmark from 'assets/icons/complete.svg';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {
  showUserEdit,
  showDeleteModal,
  hideDeleteModal,
  hideUserEdit,
  hideChangeModal,
  hideCancelSubscriptionModal,
  showCancelSubscriptionModal
} from './AccountSlice';
import ChangeAccount from './ChangeAccount';
import CancelSubscription from './CancelSubscription';
import { requestUserInfo } from 'core/services/UserActions';

export enum ModalMode {
  Hidden,
  User
}

interface Section {
  label: string;
  values: string[];
  subtitle?: string;
  action?: () => void;
}

interface AuthOption {
  label: string;
  value: string;
  linked: boolean;
  action?: () => void;
}

export interface InputField {
  label: string;
  id: string;
  type: number;
}

export const userFields: InputField[] = [
  { label: 'Name', id: 'name', type: ModalMode.User },
  { label: 'Email', id: 'email', type: ModalMode.User },
  { label: 'Mobile', id: 'mobile_phone', type: ModalMode.User }
];

export const allFields = [...userFields];

const Account: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const { currentHome, globalAccountSettings, isExpired, isEligibleRenewal } =
    useSelector((state: RootState) => state.home);
  const { name } = useSelector((state: RootState) => state.user);
  const {
    modal_mode,
    cancelSubscriptionModalVisible,
    deleteAccountModalVisible,
    changeModalVisible
  } = useSelector((state: RootState) => state.account);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [linkedSigninMethods, setLinkedSigninMethods] = useState<string[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [authOptions, setAuthOptions] = useState<AuthOption[]>([]);
  const dispatch = useDispatch();
  let history = useHistory();

  firebase.initializeApp(firebaseConfig);

  const handleError = (err: any) => {
    console.log(err);
  };

  const signInWithApple = async () => {
    gaBtnClickEvent('account_sign_in_with_apple');
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple().catch(
      handleError
    );
    if (!!result) {
      // 2. Sign in on the web layer using the id token and nonce
      const provider = new OAuthProvider('apple.com');
      const credential = provider.credential({
        idToken: result.credential?.idToken,
        rawNonce: result.credential?.nonce
      });
      let auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        auth = getAuth();
      }
      await signInWithCredential(auth, credential).then((result) => {
        // Refreshes linked accounts
        connectSignInAccounts();
      });
    }
  };

  const signInWithGoogle = async () => {
    gaBtnClickEvent('account_sign_in_with_google');
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle().catch(
      handleError
    );
    if (!!result) {
      // 2. Sign in on the web layer using the id token
      const credential = GoogleAuthProvider.credential(
        result.credential?.idToken
      );
      let auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        auth = getAuth();
      }
      await signInWithCredential(auth, credential).then((result) => {
        // Refreshes linked accounts
        connectSignInAccounts();
      });
    }
  };

  const connectSignInAccounts = () => {
    let auth;
    if (Capacitor.isNativePlatform()) {
      auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence
      });
    } else {
      auth = getAuth();
    }
    fetchSignInMethodsForEmail(auth, user.email)
      .then((result: any) => {
        setLinkedSigninMethods(result);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
      });
  };

  const goToRenew = () => {
    gaBtnClickEvent('account_renew');
    history.push(`/renew`);
  };

  const logoutUser = () => {
    gaToggle(globalAccountSettings?.impersonating === null);
    gaBtnClickEvent('log_out');
    firebase.auth().signOut().then();
    dispatch(logout());
    dispatch(resetAppToInitialState());
    history.push(`/`);
  };

  const openEditUserModal = () => {
    gaBtnClickEvent('open_edit_account_user_modal');
    dispatch(showUserEdit(ModalMode.User));
  };

  const openCancelSubscriptionModal = () => {
    gaBtnClickEvent('open_cancel_subscription_modal');
    dispatch(showCancelSubscriptionModal());
  };

  const openDeleteAccountModal = () => {
    gaBtnClickEvent('open_delete_account_modal');
    dispatch(showDeleteModal());
  };

  const closeChangeModal = () => {
    dispatch(hideChangeModal());
  };

  const closeEditModal = () => {
    dispatch(hideUserEdit());
  };

  const closeCancelSubscriptionModal = () => {
    dispatch(hideCancelSubscriptionModal());
    dispatch(requestUserInfo());
  };

  const closeDeleteModal = () => {
    dispatch(hideDeleteModal());
  };

  useEffect(() => {
    if (!!user) {
      connectSignInAccounts();
    }
  }, [user]);

  useEffect(() => {
    setAuthOptions([
      {
        label: 'Google',
        value: 'google.com',
        linked: linkedSigninMethods.includes('google.com'),
        action: signInWithGoogle
      },
      {
        label: 'Apple',
        value: 'apple.com',
        linked: linkedSigninMethods.includes('apple.com'),
        action: signInWithApple
      }
    ]);
  }, [linkedSigninMethods]);

  useEffect(() => {
    if (!!currentHome) {
      const sections = [
        {
          label: 'Contact Info',
          values: [user.email, formatPhoneNumber(user.mobile_phone)!],
          action: openEditUserModal
        },
        {
          label: 'Address',
          values: [
            currentHome.address1,
            currentHome.address2,
            `${currentHome.city}, ${currentHome.state} ${currentHome.postal_code}`
          ],
          subtitle: 'Please contact Support to change your address.'
        },
        {
          label: 'Delete Account',
          values: [''],
          action: openDeleteAccountModal
        }
      ];
      if (globalAccountSettings?.auto_renew === true) {
        sections.push({
          label: 'Cancel Subscription',
          values: [''],
          action: openCancelSubscriptionModal
        });
      }
      setSections(sections);
    }
  }, [currentHome]);

  function DesktopAccountView() {
    return (
      <>
        <IonContent>
          <div className="hk-desktop-account">
            <div className="hk-desktop-account-header">
              {name.split(' ').map((fragment, i) => {
                return <IonText key={`text${i}`}>{fragment}</IonText>;
              })}
            </div>
            <IonList className="">
              {sections.map((field, i) => {
                const { label, values, subtitle, action } = field;
                return (
                  <>
                    {!label.includes('Delete Account') && (
                      <IonItem
                        lines="none"
                        color="dark"
                        key={`item-${i}`}
                        className={`hk-desktop-account-item ${
                          !!action ? 'editable' : ''
                        }`}
                        onClick={action}
                      >
                        <div className="hk-desktop-account-container">
                          <div className="hk-desktop-account-details">
                            <IonLabel
                              className={`hk-desktop-account-label ${
                                (label.includes('Delete Account') ||
                                  label.includes('Cancel Subscription')) &&
                                'hk-delete-account-label'
                              }`}
                            >
                              <IonText>{label}</IonText>
                            </IonLabel>
                            <IonLabel>
                              {values.map((val, j) => {
                                return !!val ? (
                                  <IonText
                                    key={`val-${i}-${j}`}
                                    className="hk-desktop-account-value"
                                  >
                                    {label === 'Contact Info' && j > 0 && ', '}
                                    {val}
                                    {label === 'Address' && j === 0 && ', '}
                                  </IonText>
                                ) : null;
                              })}
                            </IonLabel>
                            {!!subtitle && (
                              <IonText className="hk-desktop-account-subtitle">
                                {subtitle}
                              </IonText>
                            )}
                          </div>
                          {!!action && (
                            <IonIcon
                              className="hk-desktop-account-edit-icon"
                              icon={EditIcon}
                              color="primary"
                            />
                          )}
                        </div>
                      </IonItem>
                    )}
                  </>
                );
              })}
            </IonList>
            <IonList className="">
              <IonItem
                lines="none"
                color="dark"
                className={`hk-desktop-account-item editable`}
              >
                <div className="hk-desktop-account-container">
                  <div className="hk-desktop-account-details">
                    <IonLabel className={`hk-desktop-account-label`}>
                      <IonText>Subscription Status</IonText>
                    </IonLabel>
                    <IonLabel>
                      {!!currentHome && (
                        <IonText className="hk-desktop-account-value">
                          {currentHome!.is_diy ? ['Digital'] : ['Full Service']}
                          :{' '}
                          {globalAccountSettings?.auto_renew === true ? (
                            <>
                              Active - Auto Renew
                              {!!globalAccountSettings?.auto_renew_next
                                ? ` on ${formatSimpleDate(
                                    globalAccountSettings?.auto_renew_next!,
                                    DATE_FORMAT_MONTH_DAY_YEAR
                                  )}`
                                : ''}
                            </>
                          ) : (
                            <>
                              {' '}
                              {isExpired
                                ? ['Expired']
                                : [
                                    `Active - Expires ${formatSimpleDate(
                                      globalAccountSettings?.expires!,
                                      DATE_FORMAT_MONTH_DAY_YEAR
                                    )}`
                                  ]}
                            </>
                          )}
                        </IonText>
                      )}
                    </IonLabel>
                  </div>
                  {globalAccountSettings?.auto_renew !== true && (
                    <IonButton
                      className="hk-account-item-subscription-action-renew"
                      color="secondary"
                      onClick={goToRenew}
                    >
                      Renew
                    </IonButton>
                  )}
                </div>
              </IonItem>
            </IonList>
            <IonList className="">
              {authOptions.map((field, i) => {
                const { label, linked, action } = field;
                return (
                  <IonItem
                    lines="none"
                    color="dark"
                    key={`auth-${i}`}
                    className={`hk-desktop-account-item ${
                      !!action ? 'editable' : ''
                    }`}
                    onClick={action}
                  >
                    <div className="hk-desktop-account-container">
                      <div className="hk-desktop-account-details">
                        <IonLabel
                          className={`hk-desktop-account-label ${
                            label.includes('Delete Account') &&
                            'hk-delete-account-label'
                          }`}
                        >
                          <IonText>{label} Sign In</IonText>
                        </IonLabel>
                        <IonLabel></IonLabel>
                      </div>
                      <div className="hk-account-item-signin-action">
                        {!!action && (
                          <IonIcon
                            slot="end"
                            className="hk-account-item-signin-icon"
                            style={{
                              fontSize: linked ? '24px' : '28px'
                            }}
                            icon={linked ? CompletedCheckmark : addOutline}
                            color="primary"
                          />
                        )}
                      </div>
                    </div>
                  </IonItem>
                );
              })}
            </IonList>
            <IonList className="">
              {sections.map((field, i) => {
                const { label, values, action } = field;
                return (
                  <>
                    {label.includes('Delete Account') && (
                      <IonItem
                        lines="none"
                        color="dark"
                        key={`item-${i}`}
                        className={`hk-desktop-account-item ${
                          !!action ? 'editable' : ''
                        }`}
                        onClick={action}
                      >
                        <div className="hk-desktop-account-container">
                          <div className="hk-desktop-account-details">
                            <IonLabel
                              className={`hk-desktop-account-label ${
                                label.includes('Delete Account') &&
                                'hk-delete-account-label'
                              }`}
                            >
                              <IonText>{label}</IonText>
                            </IonLabel>
                            <IonLabel>
                              {values.map((val, j) => {
                                return !!val ? (
                                  <IonText
                                    key={`val-${i}-${j}`}
                                    className="hk-desktop-account-value"
                                  >
                                    {label === 'Contact Info' && j > 0 && ', '}
                                    {val}
                                    {label === 'Address' && j === 0 && ', '}
                                  </IonText>
                                ) : null;
                              })}
                            </IonLabel>
                          </div>
                          {!!action && (
                            <IonIcon
                              className="hk-desktop-account-edit-icon"
                              icon={EditIcon}
                              color="primary"
                            />
                          )}
                        </div>
                      </IonItem>
                    )}
                  </>
                );
              })}
            </IonList>
            <div className="hk-desktop-account-footer">
              <IonButton
                expand="block"
                color="primary"
                strong={true}
                className=""
                onClick={logoutUser}
              >
                Sign Out
              </IonButton>
            </div>
          </div>
        </IonContent>
      </>
    );
  }

  function MobileAccountView() {
    return (
      <>
        <BackButtonHeader title={'Account'} color={'dark'} url={'/dashboard'} />
        <IonContent className="hk-account">
          <div className="hk-account-header">
            {name.split(' ').map((fragment, i) => {
              return <IonText key={`acct-text${i}`}>{fragment}</IonText>;
            })}
          </div>
          <div className="hk-account-section-title">Account Details</div>
          <IonList className="ion-margin-vertical">
            {sections.map((field, i) => {
              const { label, values, subtitle, action } = field;
              return (
                <>
                  {!label.includes('Cancel Subscription') &&
                    !label.includes('Delete Account') && (
                      <div
                        key={`acct-item-${i}`}
                        className="hk-account-item ion-margin-horizontal"
                        onClick={action}
                      >
                        <div className="hk-account-item-info">
                          <IonLabel
                            className={`hk-account-item-info-label ion-text-nowrap ${
                              label.includes('Delete Account') &&
                              'hk-delete-account-label'
                            }`}
                          >
                            <IonText>{label}</IonText>
                          </IonLabel>
                          {values.map((val, j) => {
                            return !!val ? (
                              <IonText
                                key={`acct-val-${i}-${j}`}
                                className="hk-account-item-info-value"
                              >
                                {val}
                              </IonText>
                            ) : null;
                          })}
                          {!!subtitle && (
                            <IonText className="hk-account-subtitle">
                              {subtitle}
                            </IonText>
                          )}
                        </div>
                        <div className="hk-account-item-action">
                          {!!action && (
                            <IonIcon
                              slot="end"
                              className="hk-account-edit-icon"
                              icon={EditIcon}
                              color="primary"
                            />
                          )}
                        </div>
                      </div>
                    )}
                </>
              );
            })}
          </IonList>
          <div className="hk-account-section-title">Subscription Status</div>
          <IonList>
            <div className="hk-account-item-subscription ion-margin-horizontal">
              <div className="hk-account-item-subscription-info">
                <IonLabel className="hk-account-item-subscription-info-label ion-text-nowrap">
                  {!!currentHome && (
                    <>
                      <IonText className="hk-account-item-subscription-info-label-plan">
                        {currentHome!.is_diy ? ['Digital'] : ['Full Service']}
                      </IonText>
                      <IonText className="hk-account-item-subscription-info-label-status">
                        {globalAccountSettings?.auto_renew === true ? (
                          <>
                            Active - Auto Renew
                            {!!globalAccountSettings?.auto_renew_next
                              ? ` on ${formatSimpleDate(
                                  globalAccountSettings?.auto_renew_next!,
                                  DATE_FORMAT_MONTH_DAY_YEAR
                                )}`
                              : ''}
                          </>
                        ) : (
                          <>
                            {isExpired
                              ? ['Expired']
                              : [
                                  `Active - Expires ${formatSimpleDate(
                                    globalAccountSettings?.expires!,
                                    DATE_FORMAT_MONTH_DAY_YEAR
                                  )}`
                                ]}
                          </>
                        )}
                      </IonText>
                    </>
                  )}
                </IonLabel>
              </div>
              {globalAccountSettings?.auto_renew !== true && (
                <div className="hk-account-item-subscription-action">
                  <IonButton
                    className="hk-account-item-subscription-action-renew"
                    color="secondary"
                    onClick={goToRenew}
                  >
                    Renew
                  </IonButton>
                </div>
              )}
            </div>
            {globalAccountSettings?.auto_renew === true && (
              <IonButton
                expand="block"
                strong={true}
                className="hk-account-item-subscription-cancel-button"
                onClick={openCancelSubscriptionModal}
              >
                Cancel Subscription
              </IonButton>
            )}
          </IonList>
          {isExpired && (
            <div className="hk-account-renew-banner">
              <div>
                Your subscription has expired. In order to continue to schedule
                service please renew.
              </div>
            </div>
          )}
          <div className="hk-account-section-title">Sign In Settings</div>
          <IonList className="ion-margin-vertical">
            {authOptions.map((field, i) => {
              const { label, linked, action } = field;
              return (
                <div
                  key={`acct-auth-${i}`}
                  className="hk-account-item-signin ion-margin-horizontal"
                  onClick={action}
                >
                  <div className="hk-account-item-signin-info">
                    <IonLabel className="hk-account-item-signin-info-label ion-text-nowrap">
                      <IonText>{label}</IonText>
                    </IonLabel>
                  </div>
                  <div className="hk-account-item-signin-action">
                    {!!action && (
                      <IonIcon
                        slot="end"
                        className="hk-account-item-signin-icon"
                        style={{
                          fontSize: linked ? '24px' : '28px'
                        }}
                        icon={linked ? CompletedCheckmark : addOutline}
                        color="primary"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </IonList>
          <div
            className="hk-account-delete-container"
            onClick={openDeleteAccountModal}
          >
            <div className="hk-account-delete-description">
              <div className="hk-account-delete-description-title">
                Delete Account Data
              </div>
            </div>
            <div className="hk-account-delete-icon">
              <IonIcon
                slot="end"
                className="hk-account-edit-icon"
                icon={TrashIcon}
              />
            </div>
          </div>
        </IonContent>
        <IonToolbar
          color="dark"
          className="hk-account-toolbar ion-padding-bottom"
        >
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            className="ion-margin"
            onClick={logoutUser}
          >
            Sign Out
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-account-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopAccountView()
        : MobileAccountView()}
      <IonModal
        className="hk-desktop-account-edit-modal"
        isOpen={modal_mode > ModalMode.Hidden}
        onDidDismiss={() => closeEditModal()}
        backdropDismiss={false}
      >
        <EditAccount />
      </IonModal>
      <IonModal
        className="hk-cancel-subscription-modal"
        isOpen={cancelSubscriptionModalVisible}
        onDidDismiss={() => {
          closeCancelSubscriptionModal();
        }}
        backdropDismiss={false}
      >
        <CancelSubscription />
      </IonModal>
      <IonModal
        className="hk-delete-account-modal"
        isOpen={deleteAccountModalVisible}
        onDidDismiss={() => {
          closeDeleteModal();
        }}
        backdropDismiss={false}
      >
        <DeleteAccount />
      </IonModal>
      <IonModal
        className="hk-change-account-modal"
        isOpen={changeModalVisible}
        onDidDismiss={() => {
          closeChangeModal();
        }}
        backdropDismiss={false}
      >
        <ChangeAccount />
      </IonModal>
    </IonPage>
  );
};

export default Account;
